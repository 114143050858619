<template>
  <div class="container page-global">
    <img class="home" alt="Cashless FYI" style="width:80vw;max-width:800px;" src="../assets/cashlessfyi-logo-512.png">
    
    <h1>Vendors</h1>

    <b-row>
      <b-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-3">
        <h2>Hospital Cashless Providers</h2>
        <div class="list-group" >
          <template v-for="(provider,index) in providerList('healthcare')" >
            <a :href="provider.website" target="_default" class="list-group-item list-group-item-action" :key="index">{{provider.title}}</a>
          </template>
        </div>
      </b-col>

      <b-col class="col-12 ol-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-3">
        <h2>Senior Care Cashless Providers</h2>
        <div class="list-group" >
          <template v-for="(provider,index) in providerList('senior-living')" >
            <a :href="provider.website" target="_default" class="list-group-item list-group-item-action" :key="index">{{provider.title}}</a>
          </template>
        </div>
      </b-col>
    </b-row>

    <hr/>
    <p>Want to get on this list?<br />Drop a note with your contact information to: webmaster@cashless.fyi</p>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import providers from "../data/providers.json";


export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      providers: providers
    }
  },
  methods: {
    providerList( listType )  {
      let l = this.providers.filter( (p) => { return p.markets.indexOf( listType ) >= 0 });
      return l;
    }
  }
}
</script>
<style lang="scss">


</style>
